const { default: useCurrentSalon } = require("./useCurrentSalon")

const currencies = {
  Dakar: "XOF",
  Casablanca: "Dhs",
}

const useSalonCurrency = () => {
  const currentSalon = useCurrentSalon()
  // console.log("currentSalon: ", currentSalon)
  // we fallback to "$" in case there isn't any city/currency
  return currentSalon ? currentSalon.currency || currencies[currentSalon.city] : ""
}

export default useSalonCurrency
