import { Col, Row } from "antd"
import React from "react"

import * as Styled from "./styles"
import AppLayout from "components/Layout"
import StatPeriodFilter from "components/Statistiques/PeriodFilter/StatPeriodFilter"
import {
  StatistiquesProvider,
  useStatistiqueContext,
} from "contexts/Statistiques/StatistiquesProvider"
import SalonKpis from "../../components/Statistiques/Kpis/SalonKpis"
import { SkeletonCard } from "components/Dashboard/utils"
import { DashboardProvider } from "contexts/Dashboard"
import GlobalTurnOver from "components/Dashboard/DashboardSalonCA"
import { useStatistiques } from "actions/statistiques"
import { Revenue as SalonRevenueChart } from "components/Charts"
import { DashboardEmployeesRevenue as EmployeesRevenue } from "components/Dashboard"
import StatistiquesDashboard from "components/StatistiquesDashboard"
import { useUser } from "contexts/Authentication"
import { usePermissions } from "hooks/usePermissions"
import { PERMISSIONS, ROLES } from "utils/permissions"

const StatistiqueContainer = () => {
  const { statistique } = useStatistiqueContext()

  const { data, isError, isLoading } = useStatistiques({ params: statistique })

  return (
    <>
      <DashboardProvider>
        <AppLayout.Header title={"Mon Tableau de bord"} />
        <AppLayout.Content scrollAble>
          <StatistiquesDashboard />
        </AppLayout.Content>
      </DashboardProvider>
    </>
  )
}

const StatistiquePage = () => {
  const { hasPermission } = usePermissions()
  const user = useUser()
  console.log({ hasPermission, user })

  if (!hasPermission(PERMISSIONS.STATISTIQUES) && user?.role !== ROLES.OWNER) {
    return <p>Pas accès</p>
  }

  return (
    <StatistiquesProvider>
      <StatistiqueContainer />
    </StatistiquesProvider>
  )
}

export default StatistiquePage
