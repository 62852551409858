import React from "react"
import * as Styled from "./Styles"
import Accordion from "./Accordion"
import ActivitiesTabs from "./ActivitiesTabs"
import { useParams } from "react-router"
import {
  useCashRegisterActivitiesQuery,
  useCashRegisterCashFlowsQuery,
} from "actions/cashRegisters"

const ActivitiesCaisse = () => {
  const { caisseId } = useParams()
  const { data: cashRegisterActivities, status: activitiesStatus } =
    useCashRegisterActivitiesQuery(caisseId)
  const { data: cashRegisterCashFlows, status: cashFlowsStatus } =
    useCashRegisterCashFlowsQuery(caisseId)

  const statusVente =
    activitiesStatus === "idle" || activitiesStatus === "loading"
  const statusTransaction =
    cashFlowsStatus === "idle" || cashFlowsStatus === "loading"

  return (
    <Styled.ActivitiesCaisseContainer>
      <Styled.Title>Activités du Salon</Styled.Title>

      <ActivitiesTabs
        ventes={<Accordion activities={cashRegisterActivities?.activities} />}
        transaction={<Accordion activities={cashRegisterCashFlows?.cashFlows} />}
        statusVente={statusVente}
        statusTransaction={statusTransaction}
      />
    </Styled.ActivitiesCaisseContainer>
  )
}

export default ActivitiesCaisse
