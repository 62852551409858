import { Col, Layout, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    height: 120vh;
`
export const PaiementMain = styled(Layout.Content)`
    padding: 20px;
    margin-top: 20px;
    height: 100%;
`
export const PaiementRow = styled(Row)`
    // height: 100%;
    overflow: auto;
`
export const LeftSide = styled(Col)`
    border-right: 1px solid #D9D9D9
`
export const RightSide = styled(Col)`
    background-color: #F9FAFA
`