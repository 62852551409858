import React, { useState, useRef } from "react"
import * as Styled from "../../../components/Caisse/styles"
import { Col, message, Result } from "antd"
import JourneeDeCaisseItem, {
  RowItem,
} from "../../../components/Caisse/JourneesDeCaisse/JourneeDeCaisseItem"
import TabTransactionsResumer from "../../../components/Caisse/JourneesDeCaisse/Details/TabTransactionsResumer"
import AppLayout from "components/Layout"
import BackButtonLink from "components/BackButtonLink"
import useSalonId from "hooks/useSalonId"
import { getJourneeDeCaissePath } from "routes/utils"
import CloturerCaisseModal from "components/Caisse/JourneesDeCaisse/Details/CloturerCaisseModal"
import { formattedDate } from "./data"
import { useCashRegisterDetailsQuery } from "actions/cashRegisters"
import { useNavigate, useParams } from "react-router"
import Loading from "components/Loading"
import Button from "components/Button"
import { getManagers } from "utils/caisse"
import useSalonCurrency from "hooks/useSalonCurrency"
import CashRegisters from "actions/cashRegisters/api"
import { useMutation, useQueryClient } from "react-query"
import { Download, Edit2, ShoppingBag } from "react-feather"
import ActivitiesCaisse from "components/Caisse/JourneesDeCaisse/ActivitiesCaisse"
import PdfPrint from "components/Pdf/PdfPrint"
import RapportToPrint from "components/Caisse/JourneesDeCaisse/Details/RapportToPrint"
import { useReactToPrint } from "react-to-print"
import JourneeDeCaisseResumer from "components/Caisse/JourneesDeCaisse/Details/JourneeDeCaisseResumer"
import { usePermissions } from "hooks/usePermissions"
import { useUser } from "contexts/Authentication"
import { PERMISSIONS, ROLES } from "utils/permissions"

const JourneeCaisseDetails = () => {
  const salonId = useSalonId()
  const currency = useSalonCurrency()
  const { caisseId } = useParams()
  const {
    data: cashRegister,
    status,
    isError,
  } = useCashRegisterDetailsQuery(caisseId)
  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState("1")
  const [openTransaction, setOpenTransaction] = useState(false)
  const { mutateAsync: closeCashRegister, isLoading: isClosingCashRegister } =
    useMutation(CashRegisters.close)
  const {
    mutateAsync: reOpenCashRegister,
    isLoading: isReOpeningCashRegister,
  } = useMutation(CashRegisters.reOpen)
  const queryClient = useQueryClient()
  const [openCloseRegister, setOpenCloseRegister] = useState(false)
  const componentRef = useRef()
  const { hasPermission } = usePermissions()
  const user = useUser()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleOpenTransaction = () => {
    setOpenTransaction(!openTransaction)
  }

  const handleCloseCashRegisterOk = () => {
    closeCashRegister({ salonId, cashRegisterId: cashRegister.id })
      .then(() => {
        message.success("La caisse est fermée avec succès")
        queryClient.invalidateQueries("cash_registers", {
          cashRegisterId: cashRegister.id,
        })
        queryClient.invalidateQueries("cash_registers", {
          cashRegisterId: cashRegister.id,
        })
        queryClient.invalidateQueries("current_cash_register", { salonId })
      })
      .catch((err) => {
        message.error(err.response.data.error)
      })
      .finally(() => {
        handleCloseCloseCashRegister()
      })
  }

  const handleReOpenCashRegister = () => {
    reOpenCashRegister({ salonId, cashRegisterId: cashRegister.id })
      .then(() => {
        message.success("La caisse est ouverte avec succès")
        queryClient.invalidateQueries("cash_registers", {
          cashRegisterId: cashRegister.id,
        })
        queryClient.invalidateQueries("current_cash_register", { salonId })
      })
      .catch((err) => {
        message.error(err.response.data.error)
      })
  }

  if (isError) {
    return (
      <Result
        status='500'
        title='Erreur'
        subTitle='Une erreur est survenue.'
        extra={
          <Button onClick={() => navigate(-1)} type='primary'>
            Retour
          </Button>
        }
      />
    )
  }
  const handleOpenCloseCashRegister = () => {
    setOpenCloseRegister(true)
  }
  const handleCloseCloseCashRegister = () => {
    setOpenCloseRegister(false)
  }

  const aujourdHui =
    new Date().toDateString() ===
    new Date(cashRegister?.created_at).toDateString()

  return (
    <>
      <div style={{ display: "none" }}>
        <PdfPrint ref={componentRef}>
          <RapportToPrint
            cashRegister={cashRegister ? cashRegister : null}
            currency={currency}
          />
        </PdfPrint>
      </div>

      <AppLayout.Header title={"Details journees de caisse"} />
      <AppLayout.Content scrollAble>
        <Styled.JourneeDeCaisseLayout>
          {openCloseRegister && (
            <CloturerCaisseModal
              handleCancel={handleCloseCloseCashRegister}
              handleOk={handleCloseCashRegisterOk}
              isModalOpen={openCloseRegister}
              isLoading={isClosingCashRegister}
            />
          )}
          <BackButtonLink
            to={getJourneeDeCaissePath(
              salonId,
              "caisse?type=JOURNEE_DE_CAISSE",
            )}>
            journees de caisse
          </BackButtonLink>

          {status === "idle" || status === "loading" ? (
            <Loading />
          ) : (
            <Styled.MainContaint gutter={[16, 16]}>
              <Col xs={24} md={15} style={{ flexShrink: 2 }}>
                <Styled.JourneeDeCaisseContainer
                  padding='10px 0'
                  border='0'
                  bgColor='#F9F9F9'>
                  <JourneeDeCaisseItem
                    caisse={cashRegister}
                    borderBottom='0'
                    bgColor='#FFF2F2'
                    color='#CA3E3E'
                    cursor='normal'
                  />
                </Styled.JourneeDeCaisseContainer>
                <Styled.JourneesDeCaisseTransactionContainer>
                  <Styled.JourneeDeCaisseDetailsContent>
                    <Styled.JourneesDeCaisseTransactionControl
                      pBottom='5px'
                      pTop='15px'
                      cursor='auto'>
                      <Col>
                        <RowItem
                          title='Total'
                          description={cashRegister?.current_balance + currency}
                          fs='16px'
                          color='#121212'
                          Fw='700'
                          Fs='30px'
                          Mt='10px'
                        />
                        <Styled.ColTitle
                          fw={"700"}
                          fs='38px'
                          color='#121212'></Styled.ColTitle>
                      </Col>
                    </Styled.JourneesDeCaisseTransactionControl>
                    <Styled.JourneesDeCaisseTransactionControl
                      Pb='15px'
                      borderBottom='1.2px solid #EBEAED'>
                      <Styled.ClotureJourneeCaisseContainer>
                        <Styled.ClotureJourneeCaisse>
                          {cashRegister.status === "closed" && aujourdHui ? (
                            <Styled.ButtonCaisseOpen
                              icon={
                                <Edit2
                                  size={20}
                                  style={{
                                    marginRight: "6px",
                                    fontSize: "1px",
                                  }}
                                />
                              }
                              onClick={handleReOpenCashRegister}>
                              {isReOpeningCashRegister
                                ? "Ouverture en cours..."
                                : "Rouvrir"}
                            </Styled.ButtonCaisseOpen>
                          ) : cashRegister.status !== "closed" ? (
                            <Styled.ButtonCaisse
                              icon={
                                <ShoppingBag
                                  size={19}
                                  style={{
                                    marginRight: "6px",
                                    fontSize: "1px",
                                  }}
                                />
                              }
                              onClick={handleOpenCloseCashRegister}>
                              Clôturer
                            </Styled.ButtonCaisse>
                          ) : (
                            ""
                          )}
                          <Styled.ButtonCaisse
                            icon={
                              <Download
                                size={20}
                                style={{ marginRight: "6px", fontSize: "1px" }}
                              />
                            }
                            onClick={handlePrint}>
                            Rapport
                          </Styled.ButtonCaisse>
                        </Styled.ClotureJourneeCaisse>
                        <Col>
                          <RowItem
                            title={`Caissier`}
                            description={getManagers(cashRegister?.managers)}
                            fs='14px'
                            color='#121212'
                            Fw='700'
                            Fs='18px'
                            Mt='10px'
                          />
                        </Col>
                      </Styled.ClotureJourneeCaisseContainer>
                    </Styled.JourneesDeCaisseTransactionControl>
                    <Styled.JourneeDeCaisseRow
                      cursor='auto'
                      borderBottom='0'
                      gutter={[16, 25]}>
                      <Col lg={8} xs={24}>
                        <RowItem
                          title='Date'
                          fs='14px'
                          color='#121212'
                          Fw='700'
                          Fs='18px'
                          Mt='10px'
                          description={formattedDate(cashRegister?.created_at)}
                        />
                      </Col>
                      <Col lg={8} xs={24}>
                        <RowItem
                          title={`Fond de caisse d’ouverture`}
                          fs='14px'
                          color='#121212'
                          Fw='700'
                          Fs='18px'
                          Mt='10px'
                          description={`${cashRegister?.initial_balance} ${currency}`}
                        />
                      </Col>
                      <Col lg={8} xs={24}>
                        <RowItem
                          title={`Fond de caisse fermeture`}
                          fs='14px'
                          color='#121212'
                          Fw='700'
                          Fs='18px'
                          Mt='10px'
                          description={
                            cashRegister?.closing_balance
                              ? cashRegister?.closing_balance + " " + currency
                              : "0.0 " + " " + currency
                          }
                        />
                      </Col>
                      <Col lg={8} xs={24}>
                        <RowItem
                          title={`Entrée`}
                          fs='14px'
                          color='#121212'
                          Fw='700'
                          Fs='18px'
                          Mt='10px'
                          description={`${cashRegister?.cash_flows?.encaissement} ${currency}`}
                        />
                      </Col>
                      <Col lg={8} xs={24}>
                        <RowItem
                          title={`Sortie`}
                          fs='14px'
                          color='#121212'
                          Fw='700'
                          Fs='18px'
                          Mt='10px'
                          description={`${cashRegister?.cash_flows?.decaissement} ${currency}`}
                        />
                      </Col>
                      <Col lg={8} xs={24}>
                        <RowItem
                          title={`Total des transactions journalière`}
                          fs='14px'
                          color='#121212'
                          Fw='700'
                          Fs='18px'
                          Mt='10px'
                          description={`${cashRegister?.total_revenue} ${currency}`}
                        />
                      </Col>
                    </Styled.JourneeDeCaisseRow>
                    <Styled.CaisseState paddingBottom='0' border='0'>
                      <TabTransactionsResumer
                        activeKey={activeKey}
                        setActiveKey={setActiveKey}
                        handleOpenTransaction={handleOpenTransaction}
                        dailyTransactions={cashRegister?.daily_transactions}
                        cashFlows={cashRegister?.cash_flows}
                        totalRevenue={cashRegister?.current_balance}
                        revenueByPaymentMethod={
                          cashRegister?.revenue_by_payment_method
                        }
                        initialBalance={cashRegister?.initial_balance}
                        closingBalance={cashRegister?.closing_balance}
                        aujourdHui={aujourdHui}
                      />
                    </Styled.CaisseState>
                  </Styled.JourneeDeCaisseDetailsContent>
                </Styled.JourneesDeCaisseTransactionContainer>
              </Col>
              <Col xs={24} md={9} style={{ flexShrink: 1 }}>
                  {
                    (hasPermission(PERMISSIONS.ACTIVITES) ||
                    user.role === ROLES.OWNER) && <ActivitiesCaisse />
                  }
              </Col>
            </Styled.MainContaint>
          )}
        </Styled.JourneeDeCaisseLayout>
      </AppLayout.Content>
    </>
  )
}

export default JourneeCaisseDetails
