import { useSalonsQuery } from "actions/salons"

const useCurrentSalon = () => {
  const { data: salons = [] } = useSalonsQuery()

  const currentSalon = salons[0]

  // if (!currentSalon) {
  //   throw new Error(`No salon for this user`)
  // }

  return currentSalon
}

export default useCurrentSalon
