export const detailCaisseData = {
  id: 4,
  initial_balance: "0.0",
  current_balance: "67200.0",
  closing_balance: "67200.0",
  created_at: "2025-01-23T11:23:31.730Z",
  status: "closed",
  managers: [
    {
      first_name: "Rhona",
      last_name: "Snow",
    },
  ],
  daily_transactions: [
    {
      id: 34,
      amount: "11700.0",
      payment_method: "OM",
      created_at: "2025-01-23T11:23:31.821Z",
    },
    {
      id: 35,
      amount: "5000.0",
      payment_method: "CARTE_BANCAIRE",
      created_at: "2025-01-23T11:24:19.747Z",
    },
    {
      id: 36,
      amount: "23500.0",
      payment_method: "WAVE",
      created_at: "2025-01-23T12:53:47.936Z",
    },
    {
      id: 37,
      amount: "23500.0",
      payment_method: "CARTE_BANCAIRE",
      created_at: "2025-01-23T12:54:48.491Z",
    },
    {
      id: 38,
      amount: "3500.0",
      payment_method: "WAVE",
      created_at: "2025-01-23T15:28:17.878Z",
    },
  ],
  total_revenue: "67200.0",
  revenue_by_payment_method: {
    CARTE_BANCAIRE: "28500.0",
    OM: "11700.0",
    WAVE: "27000.0",
  },
  payment_method: [
    {
      method: "Visa",
      programme: "00.0 ",
      compte: "29.000.0",
      difference: "29.000.0",
    },
    { method: "Discover", programme: "00.0", compte: "", difference: "" },
    { method: "MasterCard", programme: "00.0", compte: "", difference: "" },
    {
      method: "Orange Money",
      programme: "6.900.0",
      compte: "",
      difference: "",
    },
    { method: "Wave", programme: "6.900.0", compte: "", difference: "" },
    { method: "Free Money", programme: "00.0", compte: "", difference: "" },
    { method: "Wizall", programme: "00.0", compte: "", difference: "" },
    { method: "E-Money", programme: "00.0", compte: "", difference: "" },
    { method: "Espèce", programme: "15.200.0", compte: "", difference: "" },
  ],
}

// TODO: A deplacer dans un fichier utils/dates.js
export const formattedDate = (dateString) => {
  const date = new Date(dateString)
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  return new Intl.DateTimeFormat("fr-FR", options).format(date)
}

export const formatteTime = (dateString) => {
  const date = new Date(dateString)
  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  return `${hours}:${minutes}`
}
