import { useUser } from "contexts/Authentication"

export const usePermissions = () => {
  const user = useUser()

  const hasPermission = (permissionName) => {
    if (!user?.permissions) return false
    return user.permissions.some(
      (permission) => permission.permission_key === permissionName,
    )
  }

  return { hasPermission }
}
