import { Result, Row } from "antd"
import AppLayout from "components/Layout"
import React from "react"
import * as Styled from "./styles/index"
import { useCaisseCurrentStatus } from 'contexts/Caisse'
import { CAISSE_STATUS } from 'enums/constants'
import NouvelEncaissement from '../../components/Caisse/NouvelEncaissement/NouvelEncaissement'
import CaisseTransactions from '../../components/Caisse/Transactions/CaisseTransactions'
import StatusNavigation from '../../components/Caisse/StatusNavigation/StatusNavigation'
import { Route, Routes } from 'react-router'
import PaimentVenteRapide from './PaiementVenteRapide/PaimentVenteRapide';
import { CAISSE_VENTE_RAPIDE_PAIEMENT_PATH, ENCAISSEMENT_DECAISSEMENT_PATH, JOURNEE_CAISSE_DETAIL_PATH, VENTE_RAPIDE_PAIEMENT_RECU_PATH } from 'routes/utils'
import RecuTransaction from './RecuTransaction/RecuTransaction'
import JourneeDeCaisseDetails from "./Details"
import JourneesDeCaisse from "components/Caisse/JourneesDeCaisse"
import EncaissementDecaissementForm from "./Details/EncaissementDecaissementForm"
import { usePermissions } from "hooks/usePermissions"
import { useUser } from "contexts/Authentication"
import { PERMISSIONS, ROLES } from "utils/permissions"

const CaisseContainer = () => {
  return (
    <>
      <AppLayout.Header title={"Caisse"} />
      <AppLayout.Content scrollAble>
        <Styled.CaisseContainer>
          <Row>
            <StatusNavigation />
          </Row>
          <CaisseStatus />
        </Styled.CaisseContainer>
      </AppLayout.Content>
    </>
  )
}

const CaisseStatus = () => {
  const { currentStatus } = useCaisseCurrentStatus()
  const { hasPermission } = usePermissions()
  const { user } = useUser()
    return (
      <>
        {
          currentStatus === CAISSE_STATUS["NOUVEL_ENCAISSEMENT"] &&
            (hasPermission(PERMISSIONS.NOUVEL_ENCAISSEMENT) ||
            user?.role !== ROLES.OWNER )? (
              <NouvelEncaissement />
            ) : currentStatus === CAISSE_STATUS.TRANSACTIONS &&
              (hasPermission(PERMISSIONS.TRANSACTIONS) ||
              user?.role !== ROLES.OWNER) ? (
              <CaisseTransactions />
            ) : currentStatus === CAISSE_STATUS["JOURNEE_DE_CAISSE"] &&
              (hasPermission(PERMISSIONS.JOURNEE_DE_CAISSE) ||
              user?.role !== ROLES.OWNER) ? (
              <JourneesDeCaisse />
            ) : (
          <Result status={"error"} title='Pas de statut correspondant' />
          )
        }
      </>
    )
}

const CaissePage = () => {
  return (
    <>
      <Routes>
        <Route path={""} element={<CaisseContainer />} />
        <Route
          path={VENTE_RAPIDE_PAIEMENT_RECU_PATH()}
          element={<RecuTransaction />}
        />
        <Route
          path={CAISSE_VENTE_RAPIDE_PAIEMENT_PATH}
          element={<PaimentVenteRapide />}
        />
        <Route
          path={JOURNEE_CAISSE_DETAIL_PATH()}
          element={<JourneeDeCaisseDetails />}>
          <Route
            path={ENCAISSEMENT_DECAISSEMENT_PATH}
            element={<EncaissementDecaissementForm />}
          />
        </Route>
      </Routes>
    </>
  )
}

export default CaissePage
