import React, { useState } from "react"
import * as Styled from "./Styles"
import {
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons"

export const generatePhrase = (activity) => {
  const time = new Date(activity.created_at).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })
  const { first_name, last_name } = activity.user
  const amount = activity.amount
    ? `${activity.amount} XOF`
    : "un montant non spécifié"

  let phrase = `${first_name} ${last_name} à ${time}`

  switch (activity.activity_type || activity.flow_type) {
    case "ouverture":
      phrase = `La caisse a été ouverte par ${phrase}`
      break
    case "fermeture":
      phrase = `La caisse a été fermée par ${phrase}`
      break
    case "reouverture":
      phrase = `La caisse a été rouverte par ${phrase}`
      break
    case "operation":
      phrase = `Une opération de ${amount} a été effectuée par ${phrase}`
      break
    case "encaissement":
      phrase = `Une entrée de ${amount} a été effectué par ${phrase}`
      break
    case "decaissement":
      phrase = `Une sortie de ${amount} a été effectué par ${phrase}`
      break
    default:
      phrase = `Une transaction de ${amount} a été enregistrée par ${phrase}`
  }

  return phrase
}

const Accordion = ({ activities = [], header }) => {
  const [showAll, setShowAll] = useState(false)

  if (!Array.isArray(activities) || activities.length === 0) {
    return null
  }

  const sortedActivities = [...activities].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  )

  return (
    <Styled.AccordionContainer>
      <Styled.Header>{header}</Styled.Header>
      <Styled.Content showMore={showAll}>
        {sortedActivities.map((activity) => (
          <Styled.Transaction key={activity.id}>
            {generatePhrase(activity)}
          </Styled.Transaction>
        ))}
      </Styled.Content>
      {activities.length > 1 && (
        <Styled.ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <>
              <UpOutlined /> Masquer
            </>
          ) : (
            <>
              <RightOutlined /> Afficher plus
            </>
          )}
        </Styled.ToggleButton>
      )}
    </Styled.AccordionContainer>
  )
}

export default Accordion
