export const PERMISSIONS = {
  STATISTIQUES: "STATISTIQUES",
  CAISSE: "CAISSE",
  NOUVEL_ENCAISSEMENT: "NOUVEL_ENCAISSEMENT",
  TRANSACTIONS: "TRANSACTIONS",
  T_JOUR: "T.JOUR",
  T_SEMAINE: "T.SEMAINE",
  T_MOIS: "T.MOIS",
  JOURNEE_DE_CAISSE: "JOURNEE_DE_CAISSE",
  ACTIVITES: "ACTIVITES",
  GESTION_DES_RENDEZ_VOUS: "GESTION_DES_RENDEZ_VOUS",
  AGENDA: "AGENDA",
  CATEGORIE_ET_SERVICE: "CATEGORIE_ET_SERVICE",
  PACKS_ET_FORFAITS: "PACKS_ET_FORFAITS",
  GESTION_DES_EMPLOYES: "GESTION_DES_EMPLOYES",
  GESTION_DES_CLIENTS: "GESTION_DES_CLIENTS",
  GESTION_SALON: "GESTION_SALON",
}


export const ROLES = {
  OWNER: "Owner",
  EMPLOYEE: "Employee",
  CLIENT: "Client",
}
