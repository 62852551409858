import { Button, Tabs } from "antd"
import styled from "styled-components"

export const ActivitiesCaisseContainer = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

export const Title = styled.h2`
  text-align: start;
  font-size: 20px;
  color: #121212;
  margin-bottom: 15px;
`

export const AccordionContainer = styled.div`
  border-radius: 8px;
  margin-bottom: 20px;
  background: #f9f9f9;
  padding: 10px;
`

export const Header = styled.h4`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`

export const Transaction = styled.p`
  padding: 5px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
`

export const Content = styled.div`
  max-height: ${({ showMore }) => (showMore ? "1000px" : "50px")};
  overflow: hidden;
  transition: max-height 0.5s ease;
`

export const ToggleButton = styled.div`
width:200px;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #4a255a !important;
  font-size: 12px !important;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
`

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 14px;
    color: #4a255a;
  }

  .ant-tabs-tab-active {
    color: #8e44ad;
    font-weight: bold;
  }

  .ant-tabs-ink-bar {
    background-color: #8e44ad;
  }
`
