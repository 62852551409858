import request from "utils/request"
/**
 *
 * @param {string|number} salonId - salon id for dashboard data
 * @param {object} paginate - pagination options (page, per_page)
 */
const getAll = ({ salonId, ...paginate }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  // GET /api/v1/backoffice/cash_registers?salon_id=2&page=1&per_page=5
  return request({
    url: `backoffice/salons/${salonId}/cash_registers`,
    params: {...paginate},
    method: "GET",
  })
}

const getOne = ({ salonId, cashRegisterId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  // GET /api/v1/backoffice/cash_registers?salon_id=2&page=1&per_page=5
  return request({
    url: `backoffice/salons/${salonId}/cash_registers/${cashRegisterId}`,
    method: "GET",
  })
}

const getCurrent = ({ salonId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/cash_registers/current`,
    method: "GET",
  })
}

const getActivities = ({ salonId, cashRegisterId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/cash_registers/${cashRegisterId}/activities`,
    method: "GET",
  })
}

const getCashFlows = ({ salonId, cashRegisterId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/${cashRegisterId}/cash_flows`,
    method: "GET",
  })
}

const close = ({ salonId, cashRegisterId }) => {
  if (!cashRegisterId) {
    throw new Error("No cash register was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/cash_registers/${cashRegisterId}/close`,
    method: "PATCH",
  })
}

const reOpen = ({ salonId, cashRegisterId }) => {
  if (!cashRegisterId) {
    throw new Error("No cash register was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/cash_registers/${cashRegisterId}/reopen`,
    method: "PATCH",
  })
}

const addCashFlow = ({ salonId, cashFlow, cashRegisterId }) => {
  if (!cashRegisterId) {
    throw new Error("No cash register was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/cash_flows`,
    params: {
      cash_register_id: cashRegisterId,
      cash_flow: cashFlow
    },
    method: "POST",
  })
}

const CashRegisters = {
  getAll,
  getOne,
  close,
  reOpen,
  getCurrent,
  getActivities,
  addCashFlow,
  getCashFlows
}
export default CashRegisters
