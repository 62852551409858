import React from "react"
import { Col } from "antd"
import moment from "moment"

import * as Styled from "../styles"
import useSalonCurrency from "hooks/useSalonCurrency"
import { getManagers } from "utils/caisse"

moment.locale("fr")

export const RowItem = ({ title, description, color, Fw, Fs, Mt, fs }) => {
  return (
    <>
      <Styled.ColTitle fs={fs}>{title}</Styled.ColTitle>
      <Styled.ColDescription color={color} Fw={Fw} Fs={Fs} Mt={Mt}>
        {description}
      </Styled.ColDescription>
    </>
  )
}
const JourneeDeCaisseItem = ({
  caisse,
  handleButtonClick,
  bgColor,
  color,
  borderBottom,
  cursor,
}) => {
  const currency = useSalonCurrency()

  return (
    <Styled.JourneeDeCaisseRow
      cursor={cursor}
      onClick={handleButtonClick}
      borderBottom={borderBottom}>
      <Col span={6}>
        <RowItem
          title={moment(caisse.created_at).format("ll")}
          description={getManagers(caisse.managers)}
        />
      </Col>
      <Col span={6}>
        <RowItem
          title={`${caisse.initial_balance}${currency}`}
          description='Ouverture'
        />
      </Col>
      <Col span={6}>
        <RowItem
          title={`${caisse.closing_balance ?? "..."}${currency}`}
          description='Fermeture'
        />
      </Col>
      <Styled.LastCol span={6}>
        {caisse.status === "closed" ? (
          <Styled.ButtonClose bgColor={bgColor} color={color}>
            Fermée
          </Styled.ButtonClose>
        ) : (
          <Styled.ButtonOpen>ouverte</Styled.ButtonOpen>
        )}
      </Styled.LastCol>
    </Styled.JourneeDeCaisseRow>
  )
}

export default JourneeDeCaisseItem
