import React from "react"
import styled from "styled-components"
import { getManagers } from "utils/caisse"
import JourneeDeCaisseResumer from "./JourneeDeCaisseResumer"
import { formattedDate, formatteTime } from "pages/Caisse/Details/data"
import * as Styled from "../../styles"



const RapportToPrint = ({ cashRegister, currency }) => {

  return (
    <Styled.RapportWrapper>
      <Styled.RapportTitle>Rapport Journée de Caisse</Styled.RapportTitle>

      <Styled.InfoSection>

        <Styled.InfoRow>
          <Styled.InfoLabel>Date:</Styled.InfoLabel>
          {cashRegister?.created_at && (
            <Styled.InfoValue>
              {formattedDate(cashRegister?.created_at)} ,{" "}
              {formatteTime(cashRegister?.created_at)}
            </Styled.InfoValue>
          )}
        </Styled.InfoRow>

        <Styled.InfoRow>
          <Styled.InfoLabel>Caissier:</Styled.InfoLabel>
          <Styled.InfoValue>
            {cashRegister?.managers && getManagers(cashRegister?.managers)}
          </Styled.InfoValue>
        </Styled.InfoRow>

        <Styled.InfoRow>
          <Styled.InfoLabel>Statut:</Styled.InfoLabel>
          <Styled.InfoValue
            style={{
              color: cashRegister?.status === "closed" ? "#e74c3c" : "#27ae60",
              fontWeight: "bold",
            }}>
            {cashRegister?.status === "closed" ? "Fermée" : "Ouverte"}
          </Styled.InfoValue>
        </Styled.InfoRow>
      </Styled.InfoSection>

      <Styled.RapportTitleInfo>Détails des transactions</Styled.RapportTitleInfo>
      <JourneeDeCaisseResumer
        dailyTransactions={cashRegister?.daily_transactions}
        revenueByPaymentMethod={cashRegister?.revenue_by_payment_method}
        totalRevenue={cashRegister?.current_balance}
        initialBalance={cashRegister?.initial_balance}
        closingBalance={cashRegister?.closing_balance}
        entrance={cashRegister?.cash_flows?.encaissement}
        disbursement={cashRegister?.cash_flows?.decaissement}
      />
    </Styled.RapportWrapper>
  )
}

export default RapportToPrint
