import { Result } from 'antd'
import Button from 'components/Button'
import Loading from 'components/Loading'
import React from 'react'
import { RecuPaiementProvider } from './RecuPaiementProvider'
import AppLayout from 'components/Layout'
import * as Styled from "./styles"
import BackButtonLink from 'components/BackButtonLink'
import RecuPaiement from 'features/paiementProcedure/components/RecuPaiement/RecuPaiement'

const RecuViewFeature = ({ status, data, isError, title = "Details reçu paiement", navigateBack, navigateText = "Gestion des rendez-vous" }) => {

    if (status === "idle" || status === "loading") {
        return <Loading />
    }
    if (isError) {
        return <Result
            status='404'
            title='404'
            subTitle="Désolé , ce rendez-vous n'existe pas."
            extra={
                // <Link to={navigateBack}>
                <Button onClick={navigateBack} type='primary'>Retour</Button>
                // </Link>
            }
        />
    }

    return (
        <RecuPaiementProvider recuPaiement={data} navigateBack={navigateBack} navigateText={navigateText} >
            <AppLayout.Header title={title} />
            <AppLayout.Content scrollAble>
                <Styled.RecuPaiementLayout>
                    <BackButtonLink
                        to={navigateBack}>
                        {navigateText}
                    </BackButtonLink>
                    <Styled.RecuContainer>
                        <RecuPaiement />
                    </Styled.RecuContainer>
                </Styled.RecuPaiementLayout>
            </AppLayout.Content>
        </RecuPaiementProvider>
    )
}

export default RecuViewFeature