import {
  Input,
  Space,
  Typography,
  Form,
  Row,
  Tabs,
  Card,
  Button,
  Col,
} from "antd"
import styled from "styled-components"

export const ClientSelect = styled(Row)`
  border: 1.132px dashed rgba(221, 221, 221, 0.87);
  background: #fff;
  padding: 10px;
  width: 100%;
  transition: all ease 200ms;
  &:hover {
    background-color: rgba(221, 221, 221, 0.87);
    border-radius: 5px;
  }
  &:active {
    transform: scale(0.95);
  }
`
export const ClientNameSelect = styled(Typography.Text)`
  font-size: calc(6px + 0.5vw);
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
export const ServiceItemContainer = styled.div`
  background-color: #fff;
  min-height: 170px;
  box-shadow: ${({ active }) =>
    active
      ? "0px 4px 14px #65376A"
      : "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"};
  padding: 10px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  cursor: pointer;
  margin: 2px;
`
export const ServiceTitle = styled(Typography.Paragraph)`
  color: #707070;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0 !important;
`
export const ServiceDescription = styled(Typography.Paragraph)`
  color: #707070;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.334px;
  margin-bottom: 5px !important;
`
export const ServicePrice = styled(Typography.Text)`
  color: #121212;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.112px;
`
export const FormSearch = styled(Form)`
  .ant-input-affix-wrapper:hover {
    border-color: #000 !important;
    border-right-width: 1px;
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused {
    border-color: var(--elma-primary) !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #000 !important;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover {
    border-color: #000 !important;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #000 !important;
    border-right-width: 1px;
  }
`
export const InputSearch = styled(Input)`
  border-radius: 8px;
  ::placeholder {
    font-size: 14px !important;
  }
`
export const CaisseContainer = styled.div`
  margin-top: 20px;
`
export const CaisseState = styled.div`
  background-color: #fff;
  border: ${({ border }) => border || "1px solid #ddd"};
  padding: 20px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || "20px"};
  height: 100%;
`
export const ClientInfo = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  height: 100%;
`
export const VenteMainContaint = styled(Row)`
  min-height: 100vh;
  position: sticky;
  // width: 100%;
  // top: 500px;
`
export const VenteTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    font-size: 18px;
    font-weight: 500;
    color: #b7b7b7;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000;
    text-shadow: 0 0 0.25px currentcolor;
  }
  .ant-tabs-ink-bar {
    height: 5px;
    background: #000000 !important;
  }
`
export const VenteTitle = styled(Typography.Text)`
  font-size: 18px;
  color: #555;
  font-weight: 400;
`

export const JourneeDeCaisseContainer = styled.div`
  border: ${({ border }) => border || "1px solid #dddddd"};
  background-color: ${({ bgColor }) => bgColor || "#ffffff"};
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 900px;
  padding: ${({ padding }) => padding || ""};
`
export const JourneeDeCaisseCloturerContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor || "#ffffff"};
  border-radius: 8px;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const JourneeDeCaisseCloturerRow = styled(Row)`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ gap }) => gap || "10px"};
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
    // justify-content: space-between;
  }
`
export const JourneesDeCaisseTransactionContainer = styled.div`
  border: ${({ border }) => border || "1px solid #dddddd"};
  background-color: ${({ bgColor }) => bgColor || "#FFFFFF"};
  max-width: 900px;
  border-radius: 3px;
  padding: ${({ padding }) => padding || ""};
`
export const JourneeDeCaisseRow = styled(Row)`
  padding: 10px 20px;
  border-bottom: ${({ borderBottom }) => borderBottom || "2px dashed #e8eaed;"};
  gap-cols: ${({ gap }) => gap || ""};
  cursor: ${({ cursor }) => cursor || "pointer"};
`
export const JourneesDeCaisseTransactionRow = styled(Row)`
  margin: 0px 20px;
  padding-bottom: ${({ Pb }) => Pb || "10px"};
  border-bottom: ${({ borderBottom }) => borderBottom || "2px dashed #e8eaed;"};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CaisseTransactionRow = styled(Row)`
  margin: 10px 20px;
  padding-bottom: 0px;
  padding-top: 8px;
  border-top: ${({ borderTop }) => borderTop || "0px"};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    gap: 20px;
  }
`
export const EncaissementContainer = styled(Row)`
  margin: 10px 20px;
  padding-bottom: ${({ Pb }) => Pb || "10px"};
  margin-top: ${({ Mt }) => Mt || "30px"};
  dispay: flex;
  justify-content: center;
  align-items: center;
`
export const PrixTransaction = styled(Row)`
  color: #121212;
  font-weight: 500;
`
export const TitleResumerTransaction = styled(Row)`
  color: #121212;
  font-weight: 700;
  font-size: 18px;
`
export const JourneesDeCaisseTransactionControl = styled(Row)`
  margin: 20px 25px;
  padding-bottom: ${({ pBottom }) => pBottom || "25px"};
  padding-top: ${({ pTop }) => pTop || ""};
  border-bottom: ${({ borderBottom }) => borderBottom || "2px dashed #e8eaed;"};
  display: flex;
  justify-content: ${({ Jc }) => Jc || "space-between"};
  gap: 15px;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 0;
    justify-content: start;
  }
`
export const LastCol = styled(Col)`
  display: flex;
  flex-direction: ${({ Fd }) => Fd || "row"};
  justify-content: flex-end;
  align-items: ${({ Ai }) => Ai || "center"};
`
export const CaissierCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: end;

  @media (max-width: 768px) {
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }
`
export const ColTitle = styled(Typography.Text)`
  font-size: ${({ fs }) => fs || "18px"};
  font-weight: 400;
  color: ${({ color }) => color || "#707070"};
  font-weight: ${({ fw }) => fw || "normale"};
  padding: ${({ padding }) => padding || "0"};

  @media (max-width: 640px) {
    font-size: 12px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
`
export const ColDescription = styled(Typography)`
  font-size: ${({ Fs }) => Fs || "12px"};
  color: ${({ color }) => color || "#707070"};
  font-weight: ${({ Fw }) => Fw || "400"};
  margin-top: ${({ Mt }) => Mt || "0"};

  p {
    margin-bottom: 0px;
  }
  @media (max-width: 640px) {
    font-size: 10px;
  }
`
export const ButtonCaisse = styled(Button)`
  background-color: #ffffff;
  color: #000000;
  padding: 8px 18px !important;
  height: auto !important;
  text-transform: capitalize !important;
  border: 1px solid #dddddd !important;
  font-weight: 400;
  font-size: 16px;
  margin-right: 15px;
  display: flex;
  // align-items: center;
  justify-content: center;

  @media (max-width: 640px) {
    margin-bottom: 10px;
    font-size: 12px;
  }
  //   &:hover {
  //     background-color: rgb(214, 227, 217);
  //     color: #66c184;
  //   }
`
export const moneyImageTransaction = styled.div`
 

  @media (max-width: 860px) {
   display:none
  }
 
`

export const ButtonCaisseOpen = styled(ButtonCaisse)`
border: 1px solid #66c184 !important;
color: #66c184;
  &:hover {
      background-color: rgb(214, 227, 217);
      color: #66c184;
`

export const ButtonOpen = styled.div`
  background-color: #eef8f1;
  color: #66c184;
  padding: 5px 10px !important;
  height: auto !important;
  text-transform: uppercase !important;
  border: none !important;
  border-radius: 4px;

  // &:hover {
  //   background-color: rgb(214, 227, 217);
  //   color: #66c184;
  // }
`
export const ButtonClose = styled.div`
  background-color: ${({ bgColor }) => bgColor || "#DDDDDDDD"};
  color: ${({ color }) => color || "#000000"};
  padding: 5px 10px !important;
  height: auto !important;
  // text-transform: uppercase !important;
  border: none !important;
  border-radius: 4px;

  // &:hover {
  //   background-color: rgb(236, 236, 236);
  //   color: #000000;
  // }
`

export const ResumerHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  margin: 10px 0px !important;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;
  padding: 0 !important;
  margin: 0 !important;
`
export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 460px;
  margin-bottom: 10px;
`
export const JourDeCaisseResumer = styled.div`
  padding: 15px 0;
  border-bottom: 1.8px solid #eef8f1;
`
export const JourDeCaisseTotal = styled.div`
  width: 460px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 15px 0;
`
export const PrixTransactionContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 500px;
  justify-content: space-between;
`

export const Icone = styled.div`
  width: ${({ wit }) => wit || " 25px"};
  height: 25px;
  margin-right: 6px;
`

export const Badge = styled.div`
  border: 2px solid #82b440;
  width: 60%;
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  color: #82b440;
`

export const ButtonConfirmeCloture = styled.button`
  all: unset;
  border-radius: 4.462px;
  background: var(--elma-primary);
  color: #fff;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`
export const ButtonAnnulerCloture = styled.button`
  all: unset;
  border-radius: 4.462px;
  background: #fd0020;
  color: #fff;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const ClotureJourneeCaisseContainer = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`
export const ClotureJourneeCaisse = styled.div`
  display: flex;

  gap: 5px;
`
// ================form trasactions style========================= //

export const FormTransactionsContainer = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-top: 0;
`

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    margin-left: 20px;
    label {
      font-weight: 500;
    }`

export const FromTextArea = styled(Input.TextArea)`
  padding: 1.2em 1.5em;
  border-radius: 8px;
  border-color: #eaeaea !important;
  border-width: 2px;
  height: 60px;
  position: relative;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

export const JourneeDeCaisseLayout = styled.div`
  padding: 1rem 0;
`
export const JourneeDeCaisseDetailsContent = styled.div`
  margin-left: 15px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`

export const MainContaint = styled(Row)`
  min-height: 100vh;
  position: sticky;

`

// ============rapport style============ //

export const RapportWrapper = styled.div`
  padding: 40px 20px;
  background: white;
`

export const RapportTitle = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #1a1a1a;
`

export const RapportTitleInfo = styled.h3`
  margin-bottom: 20px;
  padding: 0;
  font-size: 18px;
  color: #333;
  border-bottom: 2px dashed #eaeaea;
  padding-bottom: 10px;
`

export const InfoSection = styled.div`
  padding: 20px 0;
  margin-bottom: 30px;
`

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px 0;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`

export const InfoLabel = styled.span`
  font-weight: 600;
  color: #666;
  width: 120px;
  flex-shrink: 0;
`

export const InfoValue = styled.span`
  color: #333;
  font-weight: 500;
`