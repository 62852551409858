import React from "react"
import * as Styled from "../../styles"
import Transactions from "./Transactions"
import JourneeDeCaisseResumer from "./JourneeDeCaisseResumer"

const TabTransactionsResumer = ({
  setActiveKey,
  activeKey,
  handleOpenTransaction,
  dailyTransactions,
  cashFlows,
  totalRevenue,
  revenueByPaymentMethod,
  initialBalance,
  closingBalance,
  aujourdHui,
}) => {
  const onChange = (key) => {
    setActiveKey(key)
  }
  console.log({cashFlows})
  const items = [
    {
      key: "1",
      label: "Transactions",
      children: (
        <Transactions
          aujourdHui={aujourdHui}
          dailyTransactions={dailyTransactions}
          handleOpenTransaction={handleOpenTransaction}
        />
      ),
    },
    {
      key: "2",
      label: "Résumé ",
      children: (
        <JourneeDeCaisseResumer
          revenueByPaymentMethod={revenueByPaymentMethod}
          totalRevenue={totalRevenue}
          initialBalance={initialBalance}
          closingBalance={closingBalance}
          entrance={cashFlows.encaissement}
          disbursement={cashFlows.decaissement}
        />
      ),
    },
  ]
  return (
    <Styled.VenteTabs
      defaultActiveKey='1'
      items={items}
      activeKey={activeKey}
      onChange={onChange}
    />
  )
}

export default TabTransactionsResumer
