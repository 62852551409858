import { Container, StatusTitle } from 'components/Appointment/Navigation/styles'
import { useUser } from 'contexts/Authentication'
import { useCaisseCurrentFilter, useCaisseCurrentStatus } from 'contexts/Caisse'
import { caisseActions } from 'contexts/Caisse/reducer'
import { CAISSE_STATUS, caisseDefaultStatus, caisseOptions } from 'enums/constants'
import { usePermissions } from 'hooks/usePermissions'
import React, { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { createAction } from 'utils'
import { PERMISSIONS, ROLES } from 'utils/permissions'



const StatusNavigation = () => {
    const { filterCaisse } = useCaisseCurrentFilter()
    const { currentStatus } = useCaisseCurrentStatus()
  const [searchParams, setSearchParams] = useSearchParams()
  const {hasPermission} = usePermissions()
  const user = useUser()


    const setCaisseStatus = useCallback((id) => {
        // Creation de l'action reducer
        const action = createAction(caisseActions.set_status, id)
        // Mettre a jour l'url params
        searchParams.set("type", id)
        setSearchParams(searchParams) // ex: ?type=MOUVEL+ENCAISSEMENT
        // Switcher vers un autre tab
        filterCaisse(action)
    }, [filterCaisse])

    useEffect(() => {
        let initialStatus = searchParams.get("type");

        if (!initialStatus) {
            initialStatus = caisseDefaultStatus; // Set default status
            searchParams.set("type", initialStatus);
            setSearchParams(searchParams);
        }

        const action = createAction(caisseActions.set_status, initialStatus);
        // Au chargment de la page, on switch vers le tab par defaut en fonction de l'url
        filterCaisse(action);
    }, [filterCaisse, searchParams, setSearchParams]);

    return (
      <>
        <Container>
          {caisseOptions
            .filter((status) =>
              !hasPermission(PERMISSIONS.NOUVEL_ENCAISSEMENT) &&
              user.role !== ROLES.OWNER
                ? status.id !== PERMISSIONS.NOUVEL_ENCAISSEMENT
                : true,
            )
            .filter((status) =>
              !hasPermission(PERMISSIONS.TRANSACTIONS) &&
              user.role !== ROLES.OWNER
                ? status.id !== CAISSE_STATUS.TRANSACTIONS
                : true,
            )
            .filter((status) =>
              (!hasPermission(PERMISSIONS.JOURNEE_DE_CAISSE) &&
              user.role !== ROLES.OWNER)
                ? status.id !== PERMISSIONS.JOURNEE_DE_CAISSE
                : true,
            )
            .map((status) => {
              return (
                <StatusTitle
                  key={status.id}
                  onClick={() => setCaisseStatus(status.id)}
                  active={status.id === currentStatus}
                  level={4}>
                  {status.title}
                </StatusTitle>
              )
            })}
        </Container>
      </>
    )
}

export default StatusNavigation
