import React from "react"
import AppLayout from "components/Layout"
import EncaissementDecaissementFormInfo from "../../../components/Caisse/JourneesDeCaisse/Details/EncaissementDecaissementFormInfo"
import EncaissementDecaissementFormProvider from "../../../components/Caisse/JourneesDeCaisse/Details/Provider"

import useSalonId from "hooks/useSalonId"
import { ArrowLeft } from "react-feather"
import { useNavigate, useParams } from "react-router"
import { Button, message } from "antd"
import { useMutation } from "react-query"
import CashRegisters from "actions/cashRegisters/api"

const EncaissementDecaissementForm = () => {
  const salonId = useSalonId()
  const navigate = useNavigate()
  const { caisseId } = useParams()
  const { mutateAsync: addCashFlow } = useMutation(CashRegisters.addCashFlow)

  const handleSubmit = React.useCallback(
    async (values, { setSubmitting, resetForm }) => {
      const cashFlow = {
        flow_type: values.type_transaction,
        amount: values.montant,
        reason: values.provenance || values.type_decaissement,
        comment: values.commentaire,
      }
      setSubmitting(true)
      try {
        await addCashFlow({
          salonId,
          cashRegisterId: caisseId,
          cashFlow,
        })
        message.success("Opération réussie")
        // we send back to home
        navigate(-1)
        resetForm()
      } catch (err) {
        message.error(err.response.data?.message)
      } finally {
        setSubmitting(false)
      }
    },
    [addCashFlow, navigate, salonId],
  )

  return (
    <>
      <AppLayout.Header title='Entrée / Sortie' />
      <AppLayout.Content scrollAble>
        <div style={{ padding: "20px" }}>
          <Button
            onClick={() => navigate(-1)}
            style={{
              padding: 0,
              display: "flex",
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "var(--elma-primary)",
              marginBottom: "2rem",
              display: "flex",
            }}
            type='link'
            icon={<ArrowLeft style={{ marginRight: "9px" }} />}>
            Details journees de caisse
          </Button>

          <EncaissementDecaissementFormProvider onSubmit={handleSubmit}>
            <EncaissementDecaissementFormInfo />
          </EncaissementDecaissementFormProvider>
        </div>
      </AppLayout.Content>
    </>
  )
}

export default EncaissementDecaissementForm
