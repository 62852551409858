import { Typography } from "antd";
import styled from "styled-components";

export const Label = styled(Typography.Text)`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.792px; 
    display: inline-block;
    margin-bottom: 10px;
`
export const DescBody = styled.div`
    border-left: 3px solid var(--elma-primary);
    padding: 5px;
`
export const Bodytext = styled(Typography.Paragraph)`
    color:${(color) => color || "#BDBDBD"};
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: ${(Mb) => Mb || "3px !important"};
    letter-spacing: 2px;
`