import React from "react"
import * as Styled from "./Styles"
import { Tabs } from "antd"
import { generatePhrase } from "./Accordion"
import Loading from "components/Loading"

const { TabPane } = Tabs

export const TabsContent = ({ activities = [] }) => {
  if (!Array.isArray(activities) || activities.length === 0) {
    return null
  }

  const sortedActivities = [...activities].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  )
  return (
    <Styled.AccordionContainer>
      {sortedActivities.map((activity) => (
        <Styled.Transaction key={activity.id}>
          {generatePhrase(activity)}
        </Styled.Transaction>
      ))}
    </Styled.AccordionContainer>
  )
}

const ActivitiesTabs = ({
  ventes,
  transaction,
  statusVente,
  statusTransaction,
}) => {
  return (
    <Styled.StyledTabs defaultActiveKey='1' tabPosition='top' animated>
      <TabPane key='1' tab={<span>Ventes</span>}>
        {statusVente ? <Loading /> : ventes}
      </TabPane>
      <TabPane key='2' tab={<span>Entrées / Sorties</span>}>
        {statusTransaction ? <Loading /> : transaction}
      </TabPane>
    </Styled.StyledTabs>
  )
}

export default ActivitiesTabs
