import React from "react"
import { FormikProvider, useFormik } from "formik"
import PropTypes from "prop-types"
import * as Yup from "yup"

const validationSchema = () => {
  return Yup.object({
  montant: Yup.number().required("Montant est requis"),
  commentaire: Yup.string(),
  type_transaction: Yup.string()
    .required("Type de transaction est requis")
    .oneOf(["encaissement", "decaissement"], "Type de transaction invalide"),
  provenance: Yup.string().when("type_transaction", {
    is: "encaissement",
    then: (schema) => schema.required("Provenance est requise"),
    otherwise: (schema) => schema.notRequired(),
  }),
  type_decaissement: Yup.string().when("type_transaction", {
    is: "decaissement",
    then: (schema) => schema.required("Type de décaissement est requis"),
    otherwise: (schema) => schema.notRequired(),
  }),
})
}

const EncaissementDecaissementFormProvider = ({ children, onSubmit = () => {} }) => {
  const formik = useFormik({
    initialValues: {
      montant: "",
      commentaire: "",
      provenance: "",
      type_transaction: "encaissement",
      type_decaissement: "",
    },
    validationSchema,
    onSubmit,
    enableReinitialize: false,
  })

  return <FormikProvider value={formik}>{children}</FormikProvider>
}

EncaissementDecaissementFormProvider.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default EncaissementDecaissementFormProvider
