import React, { useContext } from "react"
import { useAuthContext, useUser } from "contexts/Authentication"
import useSalonId from "hooks/useSalonId"
import { Context } from "contexts/Layout"
import {
  getAppointmentsListBasePath,
  getSalonPath,
  getClientHomePath,
  getCategoriesHomePath,
  getSalonSettingsPath,
  getEmployeeHomePath,
  getPackHomePath,
  getAgendaPath,
  getCaisseHomePath,
} from "routes/utils"
import {
  User,
  Clock,
  Grid,
  Folder,
  Settings,
  Users,
  Package,
  Calendar,
  ShoppingBag,
} from "react-feather"

import { SideMenu, SideBarItem } from "components/SideMenu/"
import { usePermissions } from "hooks/usePermissions"
import { PERMISSIONS, ROLES } from "utils/permissions"

const SideNavMenu = () => {
  //menu trigger action
  const [state, dispatch] = useContext(Context)
  const toggleSider = () => {
    dispatch({ type: "OPEN_MENU", payload: !state.collapsed })
  }
  const { logout } = useAuthContext()
  const salonId = useSalonId()
  const user = useUser()
  const { hasPermission } = usePermissions()
  // nav items pathnames
  const dashboardPath = getSalonPath(salonId)
  const caissePath = getCaisseHomePath(salonId)
  const appointmentsPath = getAppointmentsListBasePath(salonId)
  const clientHomePath = getClientHomePath(salonId)
  const salonSetttingsPath = getSalonSettingsPath(salonId)
  const categoriesHomePath = getCategoriesHomePath(salonId)
  const employeesHomePath = getEmployeeHomePath(salonId)
  const packHomePath = getPackHomePath(salonId)
  const agendaPath = getAgendaPath(salonId)

  return (
    // we set the default key to the current location
    // so that it would be dynamic
    <SideMenu
      toggle={state.collapsed}
      toggleSlide={toggleSider}
      logout={logout}>
      {(hasPermission(PERMISSIONS.STATISTIQUES) ||
        user.role === ROLES.OWNER) && (
        <SideBarItem
          to={dashboardPath}
          exact
          icon={Grid}
          toggle={state.collapsed}>
          Statistique
        </SideBarItem>
      )}

      {(hasPermission(PERMISSIONS.CAISSE) || user.role === ROLES.OWNER) && (
        <SideBarItem
          to={caissePath}
          icon={ShoppingBag}
          toggle={state.collapsed}>
          Caisse
        </SideBarItem>
      )}

      {(hasPermission(PERMISSIONS.GESTION_DES_RENDEZ_VOUS) ||
        user.role === ROLES.OWNER) && (
        <SideBarItem
          to={appointmentsPath}
          icon={Clock}
          toggle={state.collapsed}>
          Gestion des rendez-vous
        </SideBarItem>
      )}

      {(hasPermission(PERMISSIONS.AGENDA) || user.role === ROLES.OWNER) && (
        <SideBarItem to={agendaPath} icon={Calendar} toggle={state.collapsed}>
          Agenda
        </SideBarItem>
      )}

      {(hasPermission(PERMISSIONS.CATEGORIE_ET_SERVICE) ||
        user.role === ROLES.OWNER) && (
        <SideBarItem
          to={categoriesHomePath}
          icon={Folder}
          toggle={state.collapsed}>
          Catégories et services
        </SideBarItem>
      )}
      {(hasPermission(PERMISSIONS.PACKS_ET_FORFAITS) ||
        user.role === ROLES.OWNER) && (
        <SideBarItem to={packHomePath} icon={Package} toggle={state.collapsed}>
          Packs et forfaits
        </SideBarItem>
      )}
      {hasPermission(
        PERMISSIONS.GESTION_DES_CLIENTS) || user.role === ROLES.OWNER && (
        <SideBarItem to={clientHomePath} icon={User} toggle={state.collapsed}>
          Gestion des clients
        </SideBarItem>
      )}
      {(hasPermission(PERMISSIONS.GESTION_DES_EMPLOYES) ||
        user.role === ROLES.OWNER) && (
        <SideBarItem
          to={employeesHomePath}
          icon={Users}
          toggle={state.collapsed}>
          Gestion des employés
        </SideBarItem>
      )}
      {(hasPermission(PERMISSIONS.GESTION_SALON) ||
        user.role === ROLES.OWNER) && (
        <SideBarItem
          to={salonSetttingsPath}
          icon={Settings}
          toggle={state.collapsed}>
          Gestion salon
        </SideBarItem>
      )}
    </SideMenu>
  )
}

export default SideNavMenu
