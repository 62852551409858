import React from "react"
import authAPI from "actions/auth/api"
import { authStorage, createAction, salonStorage } from "utils"
import reducer, { initialState } from "./reducer"
import { useNavigate } from "react-router"
import { QueryClient } from "react-query"

const AuthContext = React.createContext()

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error("You need to call useContext within provider")
  }

  return context
}

export const useUser = () => {
  const { user } = useAuthContext()
  return user
}

export const AuthProvider = ({ children }) => {
  const queryClient = new QueryClient()
  const navigate = useNavigate()
  const [{ error, user, status, token, authenticated }, dispatch] =
    React.useReducer(reducer, initialState)

  const login = React.useCallback(async (params) => {
    try {
      const { access_token, expires_in } = await authAPI.login(params)
      authStorage.persist(access_token, expires_in)
      const { user } = await authAPI.fetchUser()
      /**
       * Each user is associated to a salon, so we persist the user salon
       * so that we can use it in useSalonId hook
       */
      dispatch(createAction("set_user", user))

      return navigate("/check-auth")
    } catch (err) {
      dispatch(createAction("set_error", err))
    }
  }, [])

  const logout = React.useCallback(async () => {
    try {
      await authAPI.logout()
    } catch (err) {
      dispatch(createAction("set_error", err))
      authStorage.clear()
      salonStorage.clear()
      queryClient.invalidateQueries()
      queryClient.setQueryData(["salons"], null)
      // localStorage.clear()
      // queryClient.clear()
      // queryClient.removeQueries()
      // queryClient.resetQueries()
      localStorage.removeItem("authState")
      dispatch(createAction("logout"))
      navigate("/login", { replace: true })
    } finally {
      authStorage.clear()
      salonStorage.clear()
      queryClient.invalidateQueries()
      queryClient.setQueryData(["salons"], null)
      // localStorage.clear()
      // queryClient.clear()
      // queryClient.removeQueries()
      // queryClient.resetQueries()
      localStorage.removeItem("authState")
      dispatch(createAction("logout"))
      navigate("/login", { replace: true })
    }
  }, [])

  React.useEffect(() => {
    const checkAuthState = async () => {
      const { token, tokenExpDate } = authStorage.get()
      if (!token) {
        // if there is no token we dispatch logout just incase
        // await logout()
      } else if (new Date(tokenExpDate) > new Date(Date.now())) {
        // if token hasn't expired yet
        try {
          dispatch(createAction("set_pending"))
          const { user } = await authAPI.fetchUser()

          if (!user) throw new Error("No user")
          dispatch(createAction("set_user", user))
        } catch (err) {
          await logout()
        }
      } else {
        await logout()
      }
    }
    checkAuthState()
  }, [login, logout])

  React.useEffect(() => {
    if (token) {
      const getCurrentUser = async () => {
        const { user } = await authAPI.fetchUser()
        if (!user) throw new Error("No user")
        dispatch(createAction("set_user", user))
      }
      getCurrentUser()
    }
  }, [login, token])

  const value = React.useMemo(
    () => ({
      error,
      user,
      status,
      token,
      authenticated,
      login,
      logout,
    }),
    [authenticated, error, login, logout, status, token, user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AUTH_STATUS } from "./reducer"

export default AuthProvider
