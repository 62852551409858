import { Route, Routes, useParams } from "react-router-dom"
import { ENCAISSEMENT_DECAISSEMENT_PATH } from "routes/utils"
import JourneeCaisseDetails from "./JourneeCaisseDetails"
import EncaissementDecaissementForm from "./EncaissementDecaissementForm"

const JourneeDeCaisseDetails = () => {
  const { caisseId } = useParams()

  console.log(caisseId)

  return (
    <Routes>
      <Route path='' element={<JourneeCaisseDetails />} />
      <Route
        path={ENCAISSEMENT_DECAISSEMENT_PATH}
        element={<EncaissementDecaissementForm />}
      />
    </Routes>
  )
}

export default JourneeDeCaisseDetails
