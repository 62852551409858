import React, { lazy } from "react"
import { Routes, Route } from "react-router-dom"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import CaissePage from "pages/Caisse"
import StatistiquePage from "pages/Statistiques"
import { PERMISSIONS } from "utils/permissions"
import ProtectedRoute from "./ProtectedRoute"

// this delay is to prevent flashing
// i.e when route loads too fast and the animation
// looks as if it flashed ==> bad UI
const DELAY = 350
const DashboardPage = loadable(() =>
  pMinDelay(
    import(/* webpackChunkName: "Login" */ "pages/Dashboard/index.js"),
    DELAY,
  ),
)
// const DashboardPage = lazy(() => import("pages/Dashboard/index.js"))
const ClientPage = lazy(() => import("pages/Clients"))
const AppointmentsPage = lazy(() => import("pages/Appointments"))
const EditAppointmentPage = lazy(() => import("pages/Appointments/Edit"))
const AddAppointmentPage = lazy(() => import("pages/Appointments/New"))
const PlayGround = lazy(() => import("pages/PlayGround"))
const Categories = lazy(() => import("pages/Categories"))

const Packs = lazy(() => import("pages/Packs"))

const Employees = lazy(() => import("pages/Employee"))

const Permissions = lazy(() => import("pages/Permissions"))

const SalonSettings = lazy(() => import("pages/SalonSettings"))
const SalonAgenda = lazy(() => import("pages/Agenda"))

/**
 * nested routes shouldn't have a starting "/"
 * it's added below.
 */

const routes = [
  {
    component: StatistiquePage,
    path: "",
    requiredPermission: PERMISSIONS.STATISTIQUES,
  },
  {
    component: ClientPage,
    path: "clients/*",
    requiredPermission: PERMISSIONS.GESTION_DES_CLIENTS,
  },
  {
    component: CaissePage,
    path: "caisse/*",
    requiredPermission: PERMISSIONS.CAISSE,
  },
  {
    component: AppointmentsPage,
    path: "appointments/*",
    requiredPermission: PERMISSIONS.GESTION_DES_RENDEZ_VOUS,
  },
  {
    component: SalonAgenda,
    path: "agenda/",
    requiredPermission: PERMISSIONS.AGENDA,
  },
  {
    component: PlayGround,
    path: "playground/",
    requiredPermission: null,
  },
  {
    component: EditAppointmentPage,
    path: "appointments/:appointmentId/edit",
    requiredPermission: PERMISSIONS.GESTION_DES_RENDEZ_VOUS,
  },
  {
    component: Categories,
    path: "categories/*",
    requiredPermission: PERMISSIONS.CATEGORIE_ET_SERVICE,
  },
  {
    component: Packs,
    path: "packs",
    requiredPermission: PERMISSIONS.PACKS_ET_FORFAITS,
  },
  {
    component: Employees,
    path: "employees/*",
    requiredPermission: PERMISSIONS.GESTION_DES_EMPLOYES,
  },
  {
    component: Permissions,
    path: "permissions/",
    requiredPermission: PERMISSIONS.GESTION_DES_EMPLOYES,
  },
  {
    component: AddAppointmentPage,
    path: "appointments/new",
    requiredPermission: PERMISSIONS.GESTION_DES_RENDEZ_VOUS,
  },
  {
    component: SalonSettings,
    path: "settings",
    requiredPermission: PERMISSIONS.GESTION_SALON,
  },
]

const AuthNestedRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, component: Component, requiredPermission }) => (
        <Route
          key={path}
          path={path}
          element={
            requiredPermission ? (
              <ProtectedRoute
                element={Component}
                requiredPermission={requiredPermission}
              />
            ) : (
              <Component />
            )
          }
        />
      ))}
    </Routes>
  )
}

// AuthNestedRoutes.propTypes = {
//   redirectTo: PropTypes.string,
// }

export default AuthNestedRoutes
