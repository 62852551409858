import React from "react"
import * as Styled from "../../styles"
import { Col, Row } from "antd"

const ResumerContent = ({ title, description, Fw, Fs, fw }) => {
  return (
    <>
      {description && (
        <Row
          style={{ padding: "9px 0px", alignItems: "center" }}
          justify={"space-between"}>
          <Styled.ColTitle fs='14px' fw={fw}>
            {title}
          </Styled.ColTitle>
          <Col>
            <Styled.ColDescription color='#121212' Fw={Fw} Fs={Fs} Mt='0'>
              {description ? description + " XOF" : ""}
            </Styled.ColDescription>
          </Col>
        </Row>
      )}
    </>
  )
}

const JourneeDeCaisseResumer = ({
  totalRevenue,
  revenueByPaymentMethod,
  initialBalance,
  closingBalance,
  entrance,
  disbursement,
}) => {
  const revenueData = [
    { title: "Visa", description: revenueByPaymentMethod?.VISA },
    { title: "Discover", description: revenueByPaymentMethod?.DISCOVER },
    { title: "MasterCard", description: revenueByPaymentMethod?.MASTERCARD },
    { title: "Orange Money", description: revenueByPaymentMethod?.OM },
    { title: "Wave", description: revenueByPaymentMethod?.WAVE },
    { title: "Free Money", description: revenueByPaymentMethod?.FREE },
    { title: "Wizall", description: revenueByPaymentMethod?.WIZALL },
    { title: "E-Money", description: revenueByPaymentMethod?.EMONEY },
    { title: "E-Money", description: revenueByPaymentMethod?.EM },
    { title: "Espèces", description: revenueByPaymentMethod?.ESPECE },
    {
      title: "Carte bancaire",
      description: revenueByPaymentMethod?.CARTE_BANCAIRE,
    },
    { title: "Autres", description: revenueByPaymentMethod?.Autres },
  ]

  const caisseData = [
    { title: "Fonds de caisse d’ouverture", description: initialBalance },
    { title: "Entrée", description: String(entrance) },
    { title: "Sortie", description: String(disbursement) },
    {
      title: "Fonds de caisse fermeture",
      description: String(closingBalance ?? 0),
    },
  ]
  return (
    <>
      <Styled.JourDeCaisseResumer>
        {revenueData
          .filter(
            ({ description }) =>
              description !== null && description !== undefined,
          )
          .map(({ title, description }) => (
            <ResumerContent
              key={title}
              title={title}
              description={String(description)}
              Fw='500'
              Fs='16px'
            />
          ))}
      </Styled.JourDeCaisseResumer>

      <Styled.JourDeCaisseResumer>
        {caisseData
          .filter(
            ({ description }) =>
              description !== null && description !== undefined,
          )
          .map(({ title, description }) => (
            <ResumerContent
              key={title}
              title={title}
              description={description}
              Fw='500'
              Fs='16px'
            />
          ))}
      </Styled.JourDeCaisseResumer>

      <Styled.JourDeCaisseResumer>
        <ResumerContent
          title='Total'
          description={totalRevenue}
          Fw='700'
          Fs='18px'
          fw='700'
        />
      </Styled.JourDeCaisseResumer>
    </>
  )
}

export default JourneeDeCaisseResumer
