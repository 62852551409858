import useSalonId from "hooks/useSalonId"
import CashRegisters from "./api"
import { useQuery } from "react-query"

export const useCashRegistersQuery = (pagination = {page: 1, per_page: 10}) => {
  const salonId = useSalonId()
  return useQuery(
    ["cash_registers", salonId, pagination],
    () => CashRegisters.getAll({ salonId, pagination }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onError: (err) => {
        console.log(err)
      },
    }
  )
}

export const useCashRegisterDetailsQuery = (cashRegisterId) => {
  const salonId = useSalonId()
  return useQuery(
    ["cash_registers", cashRegisterId],
    () => CashRegisters.getOne({ salonId, cashRegisterId }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onError: (err) => {
        console.log(err)
      },
    }
  )
}

export const useCashRegisterActivitiesQuery = (cashRegisterId) => {
  const salonId = useSalonId()
  return useQuery(
    ["cash_register_activities", cashRegisterId],
    () => CashRegisters.getActivities({ salonId, cashRegisterId }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onError: (err) => {
        console.log(err)
      },
    }
  )
}

export const useCashRegisterCashFlowsQuery = (cashRegisterId) => {
  const salonId = useSalonId()
  return useQuery(
    ["cash_register_cash_flows", cashRegisterId],
    () => CashRegisters.getCashFlows({ salonId, cashRegisterId }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onError: (err) => {
        console.log(err)
      },
    }
  )
}

export const useCurrentCashRegisterQuery = () => {
  const salonId = useSalonId()
  return useQuery(
    ["current_cash_register", salonId],
    () => CashRegisters.getCurrent({ salonId }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onError: (err) => {
        console.log(err)
      },
    }
  )
}
