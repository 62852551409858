import React from "react"
import * as Styled from "../../styles"
import { Col, Image, Typography } from "antd"
import moneyImage from "../../../../assets/images/money-bill.png"
import encaissementImage from "../../../../assets/images/file-plus.png"
import decaissementImage from "../../../../assets/images/Vector.png"
import { ENCAISSEMENT_DECAISSEMENT_PATH, getCaisseHomePath } from "routes/utils"
import useSalonId from "hooks/useSalonId"
import { useNavigate } from "react-router"
import { formattedDate, formatteTime } from "pages/Caisse/Details/data"
import { TRANSFER_ARGENT_IMAGE, TRANSFER_ARGENT_TYPE } from "enums/constants"
import useSalonCurrency from "hooks/useSalonCurrency"

const TransactionsItem = ({ index, dailyTransaction }) => {
  const currency = useSalonCurrency()
  const ImageMoney = ({ img, description }) => (
    <img
      style={{
        width: 40,
        height: 40,
        borderRadius: 100,
        boxShadow: "-4px 4px 0px 0px rgba(0,0,0,0.04)",
      }}
      src={img}
      alt={description}
    />
  )

  const modePaiement = {
    OM: {
      img: TRANSFER_ARGENT_IMAGE.OM,
    },
    WAVE: {
      img: TRANSFER_ARGENT_IMAGE.WAVE,
    },
    CARTE_BANCAIRE: {
      img: TRANSFER_ARGENT_IMAGE.CARTE_BANCAIRE,
    },
  }
  return (
    <Styled.CaisseTransactionRow
      borderTop={index === 0 ? "0" : "2px dashed #e8eaed"}>
      <Col lg={14} md={10} xs={24} style={{ display: "flex", alignItems: "center" }}>
        <Styled.moneyImageTransaction>
          <Image src={moneyImage} width={37} height={37} />
        </Styled.moneyImageTransaction>
        <Typography.Text style={{ marginLeft: 10 }}>
          {formattedDate(dailyTransaction?.created_at)},{" "}
          {formatteTime(dailyTransaction?.created_at)}
        </Typography.Text>
      </Col>
      <Col lg={5} md={7} xs={24}>
        {dailyTransaction?.payment_method === TRANSFER_ARGENT_TYPE.OM ||
        dailyTransaction?.payment_method === TRANSFER_ARGENT_TYPE.WAVE ||
        dailyTransaction?.payment_method ===
          TRANSFER_ARGENT_TYPE.CARTE_BANCAIRE ? (
          <ImageMoney
            img={modePaiement[dailyTransaction?.payment_method].img}
          />
        ) : (
          <Styled.PrixTransaction>
            {dailyTransaction?.payment_method}
          </Styled.PrixTransaction>
        )}
      </Col>
      <Styled.CaissierCol Fd='column' lg={5} md={7} xs={24}>
        <Col>
          <Styled.PrixTransaction>
            {dailyTransaction?.amount} {currency}
          </Styled.PrixTransaction>
        </Col>
      </Styled.CaissierCol>
    </Styled.CaisseTransactionRow>
  )
}

const Transactions = ({ dailyTransactions, aujourdHui }) => {
  const salonId = useSalonId()
  const navigate = useNavigate()

  return (
    <>
      {dailyTransactions?.map((dailyTransaction, index) => (
        <TransactionsItem
          dailyTransaction={dailyTransaction}
          key={dailyTransaction.id}
          index={index}
        />
      ))}
      <Styled.EncaissementContainer>
        <Styled.ButtonCaisse
          onClick={() => navigate(getCaisseHomePath(salonId))}>
          <Styled.Icone>
            <img
              src={encaissementImage}
              style={{
                width: "100%",
                height: "100%",
              }}
              alt='Icone'
            />
          </Styled.Icone>{" "}
          Nouvel encaissement
        </Styled.ButtonCaisse>
        {aujourdHui && (
          <Styled.ButtonCaisse
            onClick={() => navigate(ENCAISSEMENT_DECAISSEMENT_PATH)}>
            <Styled.Icone wit='35px'>
              <img
                src={decaissementImage}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                alt='Icone'
              />
            </Styled.Icone>
            Entrée / Sortie
          </Styled.ButtonCaisse>
        )}
      </Styled.EncaissementContainer>
    </>
  )
}

export default Transactions
