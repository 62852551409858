import React, { useEffect } from "react"
import * as Styled from "../../styles"
import { Form, Button, Radio, message, Row, Col } from "antd"
import { useFormikContext } from "formik"
import EncaissementDecaissementFormProvider from "./Provider"
import { Option, SelectContainer } from "components/SelectDropDown"
import Input from "components/Input"
import { useNavigate } from "react-router"

const EncaissementDecaissementFormInfo = () => {
  const navigate = useNavigate()
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    isSubmitting,
    setErrors,
    resetForm,
  } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length > 0) {
      Object.values(errors).forEach((errMsg) => {
        message.error(errMsg)
      })
    }
    setErrors({})
  }, [isSubmitting, errors])

  const handleCancel = () => {
    resetForm()
    navigate(-1)
  }
  const typeDecaissementOptions = [
    { label: "Sortie Caisse", value: "" },
    { label: "Remise en banque", value: "remise_banque" },
    { label: "Achat de matériel", value: "achat_materiel" },
    { label: "Paiement fournisseur", value: "paiement_fournisseur" },
  ]
  const ProvenanceOptions = [
    { label: "Entrée Caisse", value: "" },
    { label: "Banque", value: "banque" },
    { label: "Coffre-fort", value: "coffre_fort" },
    { label: "Argent propre", value: "argent_propre" },
  ]

  return (
    <Styled.JourneesDeCaisseTransactionContainer padding='40px 30px'>
      <Form.Item>
        <Radio.Group
          name='type_transaction'
          onChange={(e) => {
            const newValue = e.target.value
            setFieldValue("type_transaction", newValue)
            setFieldValue("provenance", "")
            setFieldValue("type_decaissement", "")
          }}
          value={values.type_transaction}>
          <Radio value='encaissement'>Entrée</Radio>
          <Radio value='decaissement'>Sortie</Radio>
        </Radio.Group>
      </Form.Item>
      <Form layout='vertical' onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            {values.type_transaction === "decaissement" ? (
              <Styled.FormItem label='Type de sortie'>
                <SelectContainer
                  name='type_decaissement'
                  value={values.type_decaissement}
                  onChange={(value) =>
                    setFieldValue("type_decaissement", value)
                  }>
                  {typeDecaissementOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </SelectContainer>
              </Styled.FormItem>
            ) : (
              <Styled.FormItem label='Provenance'>
                <SelectContainer
                  name='provenance'
                  value={values.provenance}
                  onChange={(value) => setFieldValue("provenance", value)}>
                  {ProvenanceOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </SelectContainer>
              </Styled.FormItem>
            )}
          </Col>
          <Col xs={24} md={12}>
            <Styled.FormItem label='Montant'>
              <Input
                name='montant'
                type='number'
                placeholder='125.000.0 XOF'
                onChange={handleChange}
                value={values.montant}
              />
            </Styled.FormItem>
          </Col>
        </Row>
        <Styled.FormItem label='Commentaire'>
          <Styled.FromTextArea
            name='commentaire'
            placeholder='Ajouter un Commentaire'
            onChange={handleChange}
            value={values.commentaire}
          />
        </Styled.FormItem>
        <Form.Item style={{ marginBottom: "0" }}>
          {" "}
          <Row gutter={16} justify='end'>
            <Col>
              {" "}
              <Button
                type='default'
                onClick={handleCancel}
                style={{
                  backgroundColor: "#fd0020",
                  color: "#FFFFFF",
                  border: 0,
                }}>
                Annuler
              </Button>
            </Col>
            <Col>
              <Button type='primary' htmlType='submit' disabled={isSubmitting}>
                Sauvegarder
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Styled.JourneesDeCaisseTransactionContainer>
  )
}

export default EncaissementDecaissementFormInfo
