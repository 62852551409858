import React, { useState } from "react"
import JourneeDeCaisseItem from "./JourneeDeCaisseItem"
import * as Styled from "../styles"
import { useNavigate } from "react-router"
import { JOURNEE_CAISSE_DETAIL_PATH } from "routes/utils"
import { useCashRegistersQuery } from "actions/cashRegisters"
import Loading from "components/Loading"
import { Result } from "antd"
import Button from "components/Button"

const JourneesDeCaisse = () => {
  const {
    data: cashRegisters,
    status,
    isError,
  } = useCashRegistersQuery({ page: 1, per_page: 10 })
  const navigate = useNavigate()

  if (status === "idle" || status === "loading") {
    return <Loading />
  }
  if (isError) {
    return (
      <Result
        status='500'
        title='Erreur'
        subTitle='Une erreur est survenue.'
        extra={
          <Button onClick={() => navigate(-1)} type='primary'>
            Retour
          </Button>
        }
      />
    )
  }

  return (
    <>
      <Styled.JourneeDeCaisseContainer>
        {cashRegisters?.content?.length ? cashRegisters.content.map((caisse) => (
          <JourneeDeCaisseItem
              caisse={caisse}
              key={caisse.id}
              handleButtonClick={() =>
                navigate(JOURNEE_CAISSE_DETAIL_PATH(caisse.id))
              }
            />
          )) :
            <Result title='Pas encore de caisse ouverte.' />
        }
      </Styled.JourneeDeCaisseContainer>
    </>
  )
}

export default JourneesDeCaisse
