import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

// this delay is to prevent flashing
// i.e when route loads too fast and the animation
// looks as if it flashed ==> bad UI
const DELAY = 350

const LoginPage = loadable(() =>
  pMinDelay(
    import(/* webpackChunkName: "Login" */ "pages/Login/index.js"),
    DELAY
  )
)

const RegisterPage = loadable(() =>
  pMinDelay(
    import(/* webpackChunkName: "Login" */ "pages/Register/index.js"),
    DELAY
  )
)
const ResetPassword = loadable(() =>
  pMinDelay(
    import(/* webpackChunkName: "ResetPassword" */ "pages/ResetPassword"),
    DELAY
  )
)

const routes = [
  {
    component: LoginPage,
    path: "/login",
  },

  {
    component: RegisterPage,
    path: "/register",
  },
  {
    component: ResetPassword,
    path: "/password/reset/:token",
  },
  {
    component: () => <Navigate to={"/login"} />,
    path: "/",
  },
  {
    component: () => <Navigate to={"/login"} />,
    path: "*",
  },
]

const UnauthenticatedApp = () => {
  return (
    <React.Suspense fallback={<div>....loading</div>}>
      <Routes>
        {routes.map(({ path, component: Component }) => {
          return <Route key={path} path={path} element={<Component />} />
        })}
      </Routes>
    </React.Suspense>

  )
}

export default React.memo(UnauthenticatedApp)
