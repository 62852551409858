export const AUTH_STATUS = {
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
  IDLE: "IDLE",
}

export const initialState = JSON.parse(localStorage.getItem("authState")) || {
  error: null,
  user: null,
  status: AUTH_STATUS.IDLE,
  token: null,
  authenticated: false,
  currentSalon: null,
}

export default function reducer(state, action) {
  let newState

  switch (action.type) {
    case "set_token":
      newState = {
        ...state,
        token: action.payload,
      }
      break
    case "set_user":
      newState = {
        ...state,
        user: action.payload,
        authenticated: true,
        status: AUTH_STATUS.RESOLVED,
      }
      break
    case "set_pending":
      newState = {
        ...state,
        status: AUTH_STATUS.PENDING,
      }
      break
    case "set_error":
      newState = {
        ...state,
        status: AUTH_STATUS.REJECTED,
        error: action.payload,
      }
      break
    case "logout":
      newState = {
        ...initialState,
        authenticated: false,
        status: AUTH_STATUS.RESOLVED,
      }
      break
    case "set_current_salon":
      newState = {
        ...state,
        currentSalon: action.payload,
      }
      break
    default:
      return state
  }

  // Sauvegarder le nouvel état dans le localStorage
  localStorage.setItem("authState", JSON.stringify(newState))
  return newState
}
