import { Col, Modal, Row } from "antd"
import Button from "components/Button"
import React from "react"
import * as Styled from "../../../../components/Caisse/ServicesPopulaire/styles"
import _ from "lodash"

const CloturerCaisseModal = ({ isModalOpen, handleOk, handleCancel, isLoading }) => {
  return (
    <Modal
      title='Clôturer la journée'
      width={700}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Row justify={"end"} gutter={10}>
          <Col>
            <Button
              disabled={isLoading}
              key='back'
              style={{ backgroundColor: "#fd0020" }}
              onClick={handleCancel}>
              Annuler
            </Button>
          </Col>
          <Col>
            <Button
              loading={isLoading}
              disabled={isLoading}
              style={{ backgroundColor: "var(--elma-primary)" }}
              onClick={handleOk}
            >
              {isLoading ? "En cours de fermeture...." : "Confirmer et clôturer la journée"}
            </Button>
          </Col>
        </Row>,
      ]}>
      <Row justify={"space-between"}>
        <Col span={24}>
          <Styled.Bodytext Mb='10px' color='#222'>
            En clôturant la journée, toutes les transactions seront enregistrées
            et verrouillées. Aucune modification ne sera possible après cette
            action.
          </Styled.Bodytext>

          <Styled.Label>Voulez-vous clôturer maintenant ?</Styled.Label>
        </Col>
      </Row>
    </Modal>
  )
}

export default CloturerCaisseModal
