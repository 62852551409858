import React, { useEffect } from "react"
import { usePermissions } from "hooks/usePermissions"
import { useUser } from "contexts/Authentication"
import { PERMISSIONS, ROLES } from "utils/permissions"
import { Result } from "antd"
import { useNavigate } from "react-router-dom"
import useSalonId from "hooks/useSalonId"

export const fallbackRoutes = [
  {
    path: "",
    requiredPermission: PERMISSIONS.STATISTIQUES,
  },
  {
    path: "clients",
    requiredPermission: PERMISSIONS.GESTION_DES_CLIENTS,
  },
  {
    path: "caisse",
    requiredPermission: PERMISSIONS.CAISSE,
  },
  {
    path: "appointments",
    requiredPermission: PERMISSIONS.GESTION_DES_RENDEZ_VOUS,
  },
  {
    path: "agenda",
    requiredPermission: PERMISSIONS.AGENDA,
  },
  {
    path: "playground",
    requiredPermission: null,
  },
  {
    path: "appointments/:appointmentId/edit",
    requiredPermission: PERMISSIONS.GESTION_DES_RENDEZ_VOUS,
  },
  {
    path: "categories",
    requiredPermission: PERMISSIONS.CATEGORIE_ET_SERVICE,
  },
  {
    path: "packs",
    requiredPermission: PERMISSIONS.PACKS_ET_FORFAITS,
  },
  {
    path: "employees",
    requiredPermission: PERMISSIONS.GESTION_DES_EMPLOYES,
  },
  {
    path: "permissions",
    requiredPermission: PERMISSIONS.GESTION_DES_EMPLOYES,
  },
  {
    path: "appointments/new",
    requiredPermission: PERMISSIONS.GESTION_DES_RENDEZ_VOUS,
  },
  {
    path: "settings",
    requiredPermission: PERMISSIONS.GESTION_SALON,
  },
]

const ProtectedRoute = ({ element: Element, requiredPermission }) => {
  const user = useUser()
  const idSalon = useSalonId()
  const navigate = useNavigate()
  const { hasPermission } = usePermissions()

  useEffect(() => {
    if (!hasPermission(requiredPermission) && user?.role !== ROLES.OWNER) {
      const routePermissions =
        user?.permissions?.map((p) => p?.permission_key) || []

      const accessibleRoute = fallbackRoutes.find((route) =>
        routePermissions.includes(route.requiredPermission),
      )

      return navigate(`/salons/${idSalon}/${accessibleRoute.path}`)
    }
  }, [hasPermission, requiredPermission, user?.role, idSalon, navigate])

  if (hasPermission(requiredPermission) || user?.role === ROLES.OWNER) {
    return <Element />
  }

  return (
    <Result
      status='403'
      title='403'
      subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page."
    />
  )
}

export default ProtectedRoute
