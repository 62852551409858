import React, { useEffect } from "react"
import TitlePaiement from "../TitlePaiement"
import * as Styled from "../../styles/index"
import { message, Space, Typography } from "antd"
// import CrediCard from './CrediCard/CrediCard'
import PaiementOptions from "./components/PaiementOptions"
// import EspecePaiement from './Espece/EspecePaiement'
// import TransfertArgent from './TransfertArgent./TransfertArgent'
import { useFormikContext } from "formik"
// import { PAIEMENT_TYPE_STATUS } from 'enums/constants'
import useAppointmentRecap from "hooks/useAppointmentRecap"
import useAppointmentId from "hooks/useAppointmentId"
import ButtonPaiement from "../ButtonPaiement"
import { useCurrentCashRegisterQuery } from "actions/cashRegisters"
import { Error } from "components/ClientCreationForm/styles"
import Button from "components/Button"
import Loading from "components/Loading"
import { Flex } from "components/Caisse/Transactions/styles"
import { useMutation, useQueryClient } from "react-query"
import CashRegisters from "actions/cashRegisters/api"
import useSalonId from "hooks/useSalonId"

export const MainPaiementOption = () => {
  const { isSubmitting, handleSubmit } = useFormikContext()
  const salonId = useSalonId()
  const {
    data: currentCashRegister,
    isError,
    isLoading,
  } = useCurrentCashRegisterQuery()
  const {
    mutateAsync: reOpenCashRegister,
    isLoading: isReOpeningCashRegister,
  } = useMutation(CashRegisters.reOpen)
  const queryClient = useQueryClient()

  const handleReOpenCashRegister = () => {
    reOpenCashRegister({
      salonId,
      cashRegisterId: currentCashRegister.cash_register.id,
    })
      .then(() => {
        message.success("La caisse est ouverte avec succès")
        queryClient.invalidateQueries("cash_registers", {
          cashRegisterId: currentCashRegister.cash_register.id,
        })
        queryClient.invalidateQueries("cash_register_activities", {
          cashRegisterId: currentCashRegister.cash_register.id,
        })
        queryClient.invalidateQueries("current_cash_register", { salonId })
      })
      .catch((err) => {
        message.error(err.response.data.error)
      })
  }

  return (
    <Styled.MainContent>
      <TitlePaiement>Paiement</TitlePaiement>
      <Space style={{ width: "100%", marginTop: "20px" }}>
        <Styled.PayerAvecText>Payer avec: </Styled.PayerAvecText>
      </Space>
      {/* OPTIONS DE PAIEMENTS */}
      <PaiementOptions />
      {/* <Row style={{ marginTop: "30px" }}>
            <Col span={24}>
                {
                    values.facture.mode_paiement === PAIEMENT_TYPE_STATUS.CREDIT_CARD ? <CrediCard /> :
                        values.facture.mode_paiement === PAIEMENT_TYPE_STATUS.ESPECE ? <EspecePaiement /> :
                            values.facture.mode_paiement === PAIEMENT_TYPE_STATUS.TRANSFERT_ARGETNT ? <TransfertArgent /> :
                                <Result
                                    status="warning"
                                    title="Il n'exist pas ce type de paiement"
                                />
                }
            </Col>
        </Row> */}
      <Styled.BtnContainer>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Typography>
            Une erreur est survenue, vous ne pouvez pas sauvegarder.
          </Typography>
        ) : currentCashRegister.cash_register?.status === "closed" ? (
          <>
            <Typography>La caisse est fermée.</Typography>
            <Flex>
              <Typography>
                Pour effectuer cette transaction, veuillez la
              </Typography>
              <Button
                disabled={isReOpeningCashRegister}
                style={{ backgroundColor: "var(--elma-primary)" }}
                onClick={handleReOpenCashRegister}>
                {isReOpeningCashRegister ? "Ouverture en cours..." : "Réouvrir"}
              </Button>
            </Flex>
          </>
        ) : (
          <ButtonPaiement isSubmitting={isSubmitting} onClick={handleSubmit}>
            PAYER
          </ButtonPaiement>
        )}
      </Styled.BtnContainer>
    </Styled.MainContent>
  )
}

const Paiement = () => {
  const { setFieldValue, values } = useFormikContext()
  const appointmentId = useAppointmentId()
  let { totalPrice } = useAppointmentRecap()
  useEffect(() => {
    // ! we set the facture object for paiement
    setFieldValue("facture", {
      ...values.facture,
      montant: totalPrice,
      appointment_id: appointmentId,
    })
  }, [totalPrice, appointmentId])
  return <MainPaiementOption />
}

export default Paiement
