import { useRecuTransactionQuery } from "actions/recuPaiement"
import RecuViewFeature from "features/RecuPaiement/RecuPaiement"
import useSalonId from "hooks/useSalonId"
import React from "react"
import { getCaisseTransactionPath } from "routes/utils"

const RecuTransaction = () => {
  const { status, data, isError } = useRecuTransactionQuery()
  const salonId = useSalonId()

  return (
    <>
      <RecuViewFeature
        status={status}
        data={data}
        isError={isError}
        title='Details reçu transactions'
        navigateBack={getCaisseTransactionPath(
          salonId,
          "caisse?type=TRANSACTIONS&periode=DAY",
        )}
        navigateText='Transactions'
      />
    </>
  )
}

export default RecuTransaction
