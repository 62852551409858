import { PlusSquareOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Avatar, Col, Row, Space } from 'antd'
import React, { useState } from 'react'
import * as Styled from "../styles"
import SelectClientModal from './SelectClient/SelectClientModal'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import _ from "lodash"
import ClientListItem from './SelectClient/ClientListItem'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'

const CaisseClientInfoItem = () => {
    const [openSelectClient, setOpenSelectClient] = useState(false)
    const { servicesState, dispatchServices } = useServicesContext()

    const resetClient = () => {
        dispatchServices({
            type: SERVICES_REDUCER_ACTION_TYPE.RESET_SELECTED_CLIENT
        })
    }

    return (
        <>

            {
                openSelectClient ?
                    <SelectClientModal
                        isOpen={openSelectClient}
                        onClose={() => setOpenSelectClient(false)}
                    /> : !_.isEmpty(servicesState.selectedClient) ?
                        <ClientListItem client={servicesState.selectedClient} resetClient={resetClient} /> :
                        <Styled.ClientSelect onClick={() => setOpenSelectClient(true)} style={{ cursor: "pointer" }} gutter={10} align={"middle"} >
                            <Col span={6} style={{ textAlign: "left" }}>
                                <Avatar size="large" icon={<UserOutlined />} />
                            </Col>
                            <Col span={15}>
                                <Styled.ClientNameSelect>Sélectionnez un client</Styled.ClientNameSelect>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                                <PlusSquareOutlined />
                            </Col>
                    </Styled.ClientSelect>
            }
            {
                servicesState?.panier?.length && _.isEmpty(servicesState.selectedClient) ? <Alert
                    style={{ padding: "5px", fontSize: "12px", marginTop: 5 }}
                    message="Veuillez choisir le client"
                    type="warning"
                    showIcon
                /> : null
            }
            {/* <Col style={{ marginTop: 20 }}>
                <SelectContainer
                    onSelect={(value) => {
                        const selectedClient = clients.filter(client => client.id === value)[0]
                        return dispatchServices({
                            type: SERVICES_REDUCER_ACTION_TYPE.SELECT_CLIENT,
                            payload: selectedClient
                        })
                    }}
                    value={servicesState.selectedClient?.firstName && `${servicesState.selectedClient?.firstName} ${servicesState.selectedClient?.lastName}`}
                    height={"45px"}
                    placeholder='Sélectionnez un client'>
                    {clients?.map((client) => {
                        return (
                            <Option key={client.id} value={client.id}>
                                <Typography.Paragraph style={{ margin: 0 }}>{client.firstName} {client?.lastName}</Typography.Paragraph>
                                <Typography.Text style={{ fontSize: 11, color: "#555" }}>{client.phone_object?.phone}</Typography.Text>
                            </Option>
                        )
                    })}
                </SelectContainer>
            </Col> */}
        </>
    )
}

export default CaisseClientInfoItem
